<template>
  <div
    class="profile-photo"
    :style="{ height: `${height}px`, width: `${width}px` }"
  >
    <div
      class="add-photo-input"
      :style="{ height: `${height}px`, width: `${width}px` }"
    >
      <input
        :style="{ height: `${height}px`, width: `${width}px` }"
        type="file"
        accept="image/jpeg, image/png"
        id="file"
        ref="file"
        @change="onFileSelected"
        v-on:change="handleFileUpload()"
      />
      <div v-if="!userPic.large" class="user-no-photo">
        <i class="material-icons">camera_alt</i>
      </div>
      <div
        :class="{ 'user-have-photo': userData.spartan_plus_member }"
        v-if="userPic.large"
        :style="{ height: `${height}px`, width: `${width}px` }"
      >
        <img
          id="avatar"
          v-on:load="onLoadAvatar"
          :style="{ height: `${height}px`, width: `${width}px` }"
          :src="userPic.large"
          alt="avatar"
        />
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      overlay-color="#000000"
      overlay-opacity="0.54"
    >
      <CropImageDialog
        v-bind:imgUrl="fileSrc"
        v-on:close-modal="closeDialog"
        :onMove="clearCache"
      ></CropImageDialog>
    </v-dialog>
    <v-dialog
      v-model="errorDialog"
      max-width="418"
      overlay-color="#000000"
      overlay-opacity="0.54"
    >
      <div class="error-dialog">
        <div class="dialog-header">
          <span>{{ $t("large_photo") }}</span>
        </div>
        <div class="dialog-text">
          <span>{{ $t("large_photo_advice") }}</span>
        </div>
        <div class="dialog-buttons flex-row">
          <button
            type="button"
            class="mem-btn btn-tertiary-dark"
            @click="errorDialog = false"
          >
            {{ $t("cancel") }}
          </button>
          <v-btn depressed large rounded>
            <label class="upload-label cursor-pointer" for="file">{{
              $t("select_photo")
            }}</label>
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CropImageDialog from "./CropImageDialog.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedFile: null,
      file: "",
      showFile: false,
      fileSrc: "",
      showDialog: false,
      errorDialog: false,
    };
  },
  props: ["height", "width"],
  components: {
    CropImageDialog,
  },
  methods: {
    ...mapActions(["uploadPhoto", "loadAvatar"]),
    async onFileSelected() {
      //this.selectedFile = event.target.files[0];
      //const fd = new FormData();
      //fd.append('athlete_user[photo]', this.selectedFile, this.selectedFile.name);
      // await this.uploadPhoto(fd)
    },
    handleFileUpload() {
      this.errorDialog = false;
      this.file = this.$refs.file.files[0];
      if (this.file) {
        this.fileSrc = URL.createObjectURL(this.file);
        this.showDialog = true;
        // document.body.style.overflow = "hidden";
      }
    },
    closeDialog(status) {
      // document.body.style.overflow = "inherit";
      this.$refs.file.value = "";
      this.clearCache();
      this.showDialog = false;
      if (status === 413) this.errorDialog = true;
    },
    clearCache() {
      console.log("clearing...");
      URL.revokeObjectURL(this.file);
    },
    submitFile() {
      this.showFile = true;
    },
    onLoadAvatar() {
      this.loadAvatar();
    },
  },
  computed: mapGetters(["userData", "userPic"]),
  mounted() {
    // console.log(this.height + "x" + this.width, "-avatatSize");
    // this.errorDialog = true;
  },
};
</script>

<style lang="scss">
.profile-photo {
  background: #4a4f54;
  border-radius: 93px;
  .add-photo-input {
    display: flex;
    flex-direction: column;
    .user-no-photo {
      display: flex;
      i {
        font-size: 20px;
        color: rgba(255, 255, 255, 0.2);
      }
    }
    .user-have-photo {
      img {
        border-radius: 93px;
        border: 2px solid #cf1019;
      }
    }
  }
}
.error-dialog {
  background: #282828;
  padding: 31px 24px 20px 24px;
  border-radius: 8px;
  .dialog-header {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
  }
  .dialog-text {
    line-height: 24px;
    color: #aaaaaa;
    max-width: 292px;
    margin: 19px 0 43px 0;
  }
  .dialog-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    .button-margin {
      margin-right: 10px;
    }
    .upload-label {
      height: 44px;
      padding-top: 13px;
      width: 100%;
    }
  }
}

.add-photo-input {
  position: relative;
  overflow: hidden;

  align-items: center;
  justify-content: center;

  // height: 58px;
  // width: 58px;

  border-radius: 100px;
}

.add-photo-input input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  outline: none;
  // height: 160px;
  // width: 160px;
}

#file-upload-button {
  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 900px) {
  .add-photo-input,
  .add-photo-input input[type="file"] {
    // height: 58px;
    // width: 58px;
  }
}
@media screen and (max-width: 499px) {
  .error-dialog {
    padding: 30px 16px 16px 16px;
    .dialog-text {
      margin: 19px 0 34px 0;
    }
  }
  .profile-photo {
    .add-photo-input {
      height: 100px;
      width: 100px;
      .user-no-photo {
        i {
          font-size: 38px;
        }
      }
      input[type="file"] {
        // width: 100px;
        // height: 100px;
      }
    }
  }
}
</style>
