<template>
  <div>
    <navigation-tabs v-if="screenWidth <= 499" />
    <div class="mem-content" id="mem-content">
      <navigation :position="navPosition" />
      <div class="navigation-wrap" ref="navWrap"></div>
      <transition name="slide-y-reverse-transition" mode="out-in">
        <router-view key="1"></router-view>
      </transition>
    </div>
  </div>
</template>
<script>
import Navigation from "../components/TheNavigation.vue";
import NavigationTabs from "../components/TheNavigationTabs.vue";

export default {
  name: "profile",
  data: () => ({
    navPosition: "",
    screenWidth: 0,
  }),
  methods: {
    // perhaps exist better solution to create fixed sideNav
    updateNavPosition() {
      this.navPosition = this.$refs.navWrap.offsetLeft;
      this.screenWidth = window.innerWidth;
    },
  },
  components: {
    Navigation,
    NavigationTabs,
  },
  computed: {},
  async mounted() {
    this.updateNavPosition();
  },
  created() {
    window.addEventListener("resize", this.updateNavPosition);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateNavPosition);
  },
};
</script>

<style lang="scss" scoped>
.mem-content {
  display: grid;
  grid-template-columns: 222px minmax(500px, 900px);
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
  // padding: 30px 20px 0 20px;
  // overflow-x: scroll;
}
@media screen and (max-width: 900px) {
  .mem-content {
    // padding: 31px 41px 0 21px;
    justify-content: start;
  }
}
@media screen and (max-width: 499px) {
  .mem-content {
    grid-template-columns: 1fr;
    grid-auto-columns: max-content;
    padding-right: 0;
    padding-left: 0;
    // padding: 0 0 0 0;
  }
}
</style>
