<template>
  <div class="modal-container">
    <div class="cropper-aria">
      <cropper
        ref="cropper"
        :src="imgUrl"
        class="cropper"
        backgroundClass="cropper-bg"
        :stencil-props="stencilProps"
        stencil-component="circle-stencil"
      ></cropper>
    </div>
    <div class="crop-buttons">
      <button
        type="button"
        class="mem-btn btn-tertiary-dark"
        @click="closeDialog()"
      >
        {{ $t("cancel") }}
      </button>
      <button
        type="button"
        class="mem-btn btn-secondary-dark"
        @click="createBlobEl()"
        v-if="!uploading"
      >
        {{ $t("save") }}
      </button>
      <div class="loading-process" v-if="uploading">
        <v-progress-circular
          indeterminate
          color="#FFFFFF"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CropImageDialog",
  props: ["imgUrl", "onMove"],
  data: () => ({
    stencilProps: {
      // handlers: {
      //   eastNorth: true,
      //   north: false,
      //   westNorth: true,
      //   west: false,
      //   westSouth: true,
      //   south: false,
      //   eastSouth: true,
      //   east: false,
      // },
      movable: true,
      scalable: true,
      // aspectRatio: 1,
    },
    image: null,
    uploading: false,
  }),
  methods: {
    ...mapActions(["uploadPhoto"]),
    // change({ canvas }) {
    //   if (!HTMLCanvasElement.prototype.toBlob) {
    //     this.image = canvas.msToBlob();
    //   } else {
    //     canvas.toBlob((b) => {
    //       this.image = b;
    //     }, "image/jpeg");
    //   }
    // },
    closeDialog() {
      this.onMove();
      this.$emit("close-modal");
    },
    async createBlobEl() {
      const { canvas } = this.$refs.cropper.getResult();
      if (!HTMLCanvasElement.prototype.toBlob) {
        this.image = canvas.msToBlob();
        await this.uploadImage();
      } else {
        canvas.toBlob(async (b) => {
          this.image = b;
          await this.uploadImage();
        }, "image/jpeg");
      }
    },
    async uploadImage() {
      if (this.uploading) return;
      const fd = new FormData();
      fd.append("athlete_user[avatar]", this.image, "profile_image");
      this.uploading = true;
      const res = await this.uploadPhoto(fd);
      this.uploading = false;
      this.$emit("close-modal", res.status);
    },
    defaultSize() {
      return { width: 320, height: 320 };
    },
  },
  mounted() {},
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.54);
  display: grid;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  overflow: scroll;
  /* margin: 20px 0; */
}

.modal-wrapper {
  display: flex;
  /* max-width: 100vw; */
  max-width: 488px;
  width: 100%;
}

.modal-wrapper-layout {
  padding: 20px 16px;
  width: 100%;
}

.modal-container {
  max-width: 488px;
  width: 100%;
  margin: 0 auto;
  background-color: #222222;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.cropper {
  height: auto;
  width: 100%;
}
.cropper-bg {
  background: #222222;
  border-radius: 8px;
}

.cropper-aria {
  padding: 16px 16px 0 16px;
}

.crop-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  margin-top: 16px;
  padding: 0 16px 16px 16px;
}
.comp-button {
  height: 44px;
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 150px;
}
.loading-process {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-button {
  background: #e0e0e0;
  margin-right: 16px;
}
.accept-button {
  background: #cf1019;
  color: #ffffff;
}

@media screen and (max-width: 599px) {
  .modal-wrapper {
    max-width: 100vw;
  }
}
</style>
